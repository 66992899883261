@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";
@import "mixins";

.button {
  @include rowCenteredInline();
  @include mediumCapitalFont();
  @include buttonTransitions();
  background-color: var(--white);
  border-radius: rem-calc(4);
  border: none;
  box-shadow: 0 0 0 rem-calc(6) transparent;
  color: var(--error);
  padding: rem-calc(12) rem-calc(20);
  position: relative;
  cursor: pointer;
}

.buttonDisabled {
  background-color: rgba($light-white, 0.3);
  color: var(--darkGreyBlue);
  cursor: not-allowed;
}

.buttonChecked {
  background-color: var(--errorFill);
}

.buttonCompleted {
  background-color: var(--errorFill);
}

.buttonDisabled {
  background-color: var(--white);
  color: var(--darkGreyBlue);
}

.buttonEnabled {
  &:hover {
    background-color: var(--errorFill);
  }
}

.buttonLoading {
  background-color: var(--errorFill);
}

.buttonWithoutMouseFocus {
  &:focus {
    box-shadow: 0 0 0 rem-calc(4) $focus-indicator-outline;
  }
}
.retainer {
  @include rowCentered;
  min-height: rem-calc(24);
  min-width: rem-calc(64);
}

//Circle Icon Styles
.circledIcon {
  @include circledIconSize;
  position: relative;
  top: rem-calc(-4);
}

.iconCircle {
  @include iconCircleBorder();
  @include iconCircleSize();
  border-color: var(--error);
}

.iconCircleBeforeLabel {
  margin-right: rem-calc(8);
}

.progress {
  color: var(--error);
  padding: 0;
}
