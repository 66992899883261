@import "shared/src/sass/abstracts/mixins";

$filterBreakpoint: 904px;

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 10px;

  &.withDateRange {
    flex-direction: column;
    align-items: flex-start;

    .searchWrapper {
      width: 100%;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: $filterBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
  }

  .searchWrapper {
    display: flex;
    flex-grow: 1;

    @media screen and (max-width: $filterBreakpoint) {
      width: 100%;
      padding-bottom: 0;
    }
  }

  .filterOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: $max-phone) {
      .tabletDesktop {
        display: none;
      }
    }

    @media screen and (max-width: $filterBreakpoint) {
      width: 100%;

      .filterSelect {
        flex: 1;
      }
    }
  }
}