@import 'shared/src/sass/abstracts/mixins';

.statsContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  padding-bottom: 20px;

  @media screen and (max-width: $max-phone) {
    padding: 0 20px 20px;  
  }
}