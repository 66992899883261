@import "shared/src/sass/abstracts/mixins";
@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

$cardWidth: 227px;
$cardPadding: 18px;
$cardContentWidth: calc($cardWidth - 2 * $cardPadding - 2px);

.statsCard {
  width: $cardWidth;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--grey100);
  border-radius: 5px;
  background-color: var(--white);
  padding: $cardPadding;
  gap: 10px;

  .groupsListDrawerContainer & {
    border: none;
    width: 100%;
    padding: 0;
  }

  .totalContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    .total {
      font-size: 11px;
      font-weight: 400;
      color: var(--darkGrey);
    }

    .count {
      font-size: 26px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
    }
  }

  .percentLineGraph {
    width: 100%;
    height: 18px;
  }

  .formTypeContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 10px;

    .icon {
      width: 34px;
      height: 34px;
      background-color: var(--activeFill);
      border-radius: 4px;
    }

    .formType {
      display: inline-block;
      width: 145px;
      padding-top: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
      .groupsListDrawerContainer & {
        display: flex;
        width: 100%;
      }
    }
  }

  .groupsContainer {
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid var(--grey100);
    border-bottom: 1px solid var(--grey100);

    .groupWrapper {
      display: block;
      width: $cardContentWidth;
      border-bottom: 1px solid transparent;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid var(--primary);
      }

      .groupsListDrawerContainer & {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
      }

      .count {
        display: inline-block;
        width: 24px;
        text-align: right;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: var(--black);
      }

      .groupName {
        display: inline-block;
        width: 110px;
        height: 12px;
        padding: 0 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 11px;
        color: var(--primary);
        line-height: 15px;

        .groupsListDrawerContainer & {
          width: auto;
          flex: 1;
          text-align: left;
        }
      }

      .percentage {
        display: inline-block;
        width: 41px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: var(--black);
      }
    }
  }

  .viewAllGroups {
    @include reset-a;
    @include focus-indicator-appear;
    @include focus-indicator-disappear;

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary);
    color: $white;
    font-size: rem-calc(13);
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 24px;
    padding: 7px 12px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;

    &:focus-visible,
    &:hover {
      background-color: var(--secondary);
    }
  }
}
