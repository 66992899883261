@import 'shared/src/sass/abstracts/mixins';

.pageContent {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 20px;
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-radius: 5px;
  gap: 20px;

  @media screen and (max-width: $max-phone) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .inputContainer, .selectContainer {
      display: flex;
      flex-direction: column;
      width: 350px;
      gap: 20px;
    }
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}