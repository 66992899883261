@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

.profileCard {
  background-color: var(--primary);
  width: 100%;
  padding: 36.5px 24px;

  @media screen and (min-width: $min-desktop) {
    border-radius: 4px;
  }

  @media screen and (max-width: $max-tablet) {
    border-bottom: 8px solid var(--lightWhite);
  }
}

.adfs {
  margin-bottom: 25.5px;
}

.card {
  max-width: 1280px;
}

.star {
  margin-right: 5px;
  margin-top: 2.5px;
  font-size: 16px;
}

.icon {
  margin-right: 8px;
  font-size: 1.35rem;
  background-color: var(--white);
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userName {
  font-size: rem-calc(20);
  color: var(--white);
  margin-bottom: 5px;
  letter-spacing: 0.63px;

  @media screen and (min-width: $min-desktop) {
    font-size: rem-calc(36);
    margin-right: 10px;
  }
}

.userName_desktop {
  margin-bottom: 12px;
}

.email {
  color: var(--white);
  font-size: 1rem;
  font-weight: 400 im !important;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

.group {
  text-align: left;
  margin: 0;
  color: var(--white);
  font-size: 1rem;
  font-weight: 400 im !important;
  letter-spacing: 0.28px;
}

.formCounts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  min-width: 300px;

  a {
    text-decoration: none;
  }
}

.formcounts_desktop {
  flex-direction: column;
}

.formCount {
  display: flex;
  align-items: center;
  min-width: 70px;
}

.formCount_desktop {
  margin-bottom: 16px;
}

.groupRow {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 100%;
  padding: 10px 25px 10px 0;
}

.count {
  color: var(--white);
  font-size: rem-calc(20);
  text-decoration: underline;
}

.count_desktop {
  text-decoration: underline;
}

.clickable {
  border: none;
  background: none;
}

.row {
  display: flex;
}

.leftTop {
  display: flex;
  margin-right: 150px;
}

.groupCol {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.sectionLabel {
  color: var(--white);
  font-size: rem-calc(12);
  font-weight: 500;
  letter-spacing: 1.88px;
  margin-bottom: 10px;
}

.supervisor {
  color: var(--white);
  font-size: 1rem;
  font-weight: 400 !important;
  letter-spacing: 0.28px;
}
