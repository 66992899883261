.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .buttonContainer {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }
}