@import "shared/src/sass/abstracts/mixins";

.groupFormContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}