@import "shared/src/sass/abstracts/mixins";

.widgetContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 20px;
  margin: 0 20px 20px;
  gap: 20px;
  flex-wrap: wrap;

  .chartHeaderContainer {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;

    .chartTitle {
      @include font-h6-style;
      white-space: nowrap;
    }

    .countIconWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .safetyCount {
        @include font-hero-style;
        color: var(--eerieBlack);
      }

      .iconWrapper {
        display: flex;
        width: 34px;
        height: 34px;
        justify-content: center;
        align-items: center;
        background-color: var(--activeFill);
      }
    }
  }

  .chartContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;

    .circleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      @include font-footnote-style;

      div {
        display: flex;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        @include font-h3-style;
      }

      &:nth-child(1) {
        color: #E3590A;

        div {
          border: 1px solid #E3590A;
          background-color: #FFEBE0;
        }
      }

      &:nth-child(2) {
        color: #E09E21;

        div {
          border: 1px solid #E09E21;
          background-color: #FFF9EF;
        }
      }

      &:nth-child(3) {
        color: #0172CE;

        div {
          border: 1px solid #0172CE;
          background-color: #E5F1FA;
        }
      }

      &:nth-child(4) {
        color: #00853C;

        div {
          border: 1px solid #00853C;
          background-color: #E5FFF2 ;
        }
      }
    }
  }
}
