@import "shared/src/sass/abstracts/mixins";

.formReportHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: -17px;
  gap: 33px;

  @media screen and (max-width: $max-phone) {
    padding: 0 15px;
 }

  .formTitle {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .formSubtitleWrapper {
    display: flex;
    flex-direction: row;
    gap: 100px;

    .formSubtitle {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  } 

  h2 {
    @include font-h2-style;
    color: var(--extraDarkGrey);
  }

  h4 {
    @include font-h4-style;
    color: var(--darkGrey);
  }
}
