@import "../../sass/abstracts/mixins";

.linkBtn {
  background: none;
  border: none;
  padding: 0;
  color: var(--primary);
  text-decoration: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.255px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.iconBtn {
  font-size: 1.5rem;
  padding: 0;
  background: none;
  border: none;
  color: var(--primary);
  display: flex;
  align-items: center;
}
