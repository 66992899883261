@import 'shared/src/sass/abstracts/mixins';
@import './modals.module.scss'; // hopefully deprecated...
@import 'shared/src/components/Generic/Modal/GenericModal.module';

.formWrapper {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 20px;
}