.formReport {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--lightGrey);
  border-radius: 5px;
  padding: 0px 0;
  margin-top: 33px;

  .summaryContainer {
    padding: 0 20px;
  }
}