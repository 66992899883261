@import "../../sass/abstracts/mixins";
  
.documentsListHeader {
  display: flex;
  color: $dark-grey;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media screen and (max-width: $max-phone) {
    &.documents {
      padding: 0 15px;
    }
  }
}

.formTypeIcon {
  border-radius: 4px;
  font-size: rem-calc(20);
  padding: 5px 5px 5px 0;
}