@import "shared/src/sass/abstracts/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: rem-calc(21);
}

.map {
  height: rem-calc(300);
  width: "100%";
}
