@import "shared/src/sass/abstracts/mixins";

.buttonWrapper {
  display: flex;
  gap: 5px;

  .buttonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonLabel {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
}