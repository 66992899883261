@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

.listContainer {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 20px 0;
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-radius: 5px;
  gap: 20px;

  @media screen and (max-width: $max-phone) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .firstCell {
    padding-left: 20px !important;
  }

  .lastCell {
    padding-right: 0px !important;
  }

  .paginationWrapper {
    margin: -20px 0;
    padding: 0 20px;
  }
}

// can be deprecated for new design on vieweditformtype file
.pageTitle {
  color: $black;
  flex-grow: 1;
  font-family: $font-family-body;
  font-size: rem-calc(24);
  font-weight: $font-weight-medium;
  letter-spacing: 0.26px;
  line-height: 0.82;
}

