@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

.a {
  color: var(--primary);
  font-size: rem-calc(14);
  line-height: rem-calc(22);
}

.button {
  background: none;
  border: none;
  border-radius: rem-calc(4);
  box-shadow: 0 0 0 rem-calc(6) transparent;
  color: var(--primary);
  cursor: pointer;
  font-size: rem-calc(14);
  line-height: rem-calc(24);
  padding: 0 rem-calc(8) 0 rem-calc(8);
  text-decoration: underline;
  @include focus-indicator-appear(box-shadow);

  &:focus {
    box-shadow: 0 0 0 rem-calc(4) var(--yellow);
  }
  &:hover {
    color: var(--secondary);
  }
}

.buttonMouseFocused {
  &:focus {
    box-shadow: 0;
  }
}

.span {
  color: var(--primary);
  display: block;
  font-size: rem-calc(14);
}

.label {
  @include font-label-style;

  &.smallLabel {
    color: var(--darkGrey);
    margin-bottom: rem-calc(5);
    font-size: rem-calc(13);
  }
}

.wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.25rem;

  &.noMargin {
    margin-top: 0;
  }
}

.withAssistiveLink {
  justify-content: space-between;
}

.required {
  display: inline-flex;
  color: var(--warning);
  padding-left: 5px;

  &.error {
    color: var(--error);

    .requiredIcon {
        fill: var(--error);
    }
  }

  .requiredIcon {
    fill: var(--warning);
  }
  
  .requiredTooltip {
    display: none;
    font-size: 10px;
    vertical-align: middle;
    margin-left: 5px;
  }

  .requiredIconWrapper:hover+.requiredTooltip {
    display: inline-block;
  }
}
 