@charset "UTF-8";

@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.firstCell {
  padding-left: 20px !important;
  padding-right: 0 !important;
}

.lastCell {
  display: flex;
  flex-direction: row;
  padding-right: 20px !important;
}

.iconBox {
  background-color: var(--activeFill);
  width: 34px;
  height: 34px;
  border-radius: 4px;
}

.alignValue {
  padding: 21px !important;
}

.paginationWrapper {
  padding: 0 20px;
}

.formName span {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;

  > span:first-child {
    font-weight: 500;
    color: var(--black);
  }

  > span:last-child {
    font-weight: 400;
    color: var(--grey)
  }
}

.summaryWrapper {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    background: transparent;
    display: none;
  }
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-top: 24px;
  gap: 10px;
}

.selectWrapper {
  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }
}

.subText {
  display: block;
  color: var(--darkGrey);
}

.reportLink {
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-weight: 400;
  color: var(--primary);
  line-height: 18px;
  cursor: pointer;
  
  span {
    text-decoration: underline;
  }

  &Icon {
    margin: 0;
    font-size: 1.2rem;
  }

  .reportLinkIcon {
    margin: 0;
  }

  &:hover span {
    text-decoration: none;
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
    white-space: wrap;
  }
}
.formTypesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
}

.drawerTitle {
  display: flex;
  align-items: center;
}

.drawerTitleText {
  flex-grow: 1;
  color: var(--black);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: center;
  text-align: center;
}

.titleIcon {
  padding-left: 12px;
}

@media screen and (max-width: 615px) {
  .tableRowHeader {
    vertical-align: top !important;
  }
}

@media screen and (max-width: 460px) {
  .groupUsageColumn {
    display: none !important;
  }
}