@import "shared/src/sass/abstracts/variables";

:root {
  --icon-color: #000000;
}

.wrapper {
  display: flex;
  margin-top: 1rem;
}

.selectionArea {
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 112px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 32px);
  gap: 8px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: var(--icon-color);
  border: 1px solid $dark-grey;
  border-radius: 3px;
  background-color: $white;
  cursor: pointer;
}

.selectedIcon {
  border: 3px solid var(--icon-color);
}

.filterInput {
  width: 550px;
}

.outputRow {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 10px;
}

.colorContainer {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 0 0 170px;
}

.previewContainer {
  flex: 0 0 100px;
}

.previewRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--icon-color);
  border: 1px solid $dark-grey;
  border-radius: 3px;
  margin-right: 8px;
  background-color: $white;
}

.clearButton {
  color: $error;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.colorPickerLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.colorPicker {
  width: 44px; // input[type="color"] is weird. Have to add extra pixels to make it square.
  height: 32px;
  background-color: var(--icon-color);
  margin: 0;
  padding: 0;
	border: none;
  border-radius: 3px;
  &::-webkit-color-swatch {
    border: none;
    border-radius: 3px;
  }
  &::-moz-color-swatch {
    border: none;
    border-radius: 3px;
  }
}

.colorPickerHexInput {
  overflow: hidden;
  padding: 5px;
  width: 135px;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.error {
  border: 1px solid $error !important;
  background-color: var(--errorFill) !important;
}

.errorText {
  color: $error;
}

.buttonRow {
  margin-top: 30px; // height of the labels
  display: flex;
  align-items: center;
  margin-left: auto;
}

.cancelButton {
  color: $error;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 8px;
  cursor: pointer;
}

.addButton {
  padding: 5px 0;
}