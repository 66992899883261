@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/mixins";

.searchBarWrapper {
  width: 100%;
}

.searchIcon {
  color: var(--primary);
  font-size: 17px;
}

.noPaddingLeft {
  padding-left: 0px;
}

.searchInput {
  background-color: var(--white);
  border: 1px solid var(--border);
  @include font-subtext-style;
  padding: 14px 16px;
  color: var(--darkGrey);
  border-radius: 5px;
  ::placeholder {
    opacity: 1;
  }
  
  &:focus-within {
    border-color: var(--primary);
  }
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clearSearch {
  align-items: center;
  color: $error;
  font-weight: $font-weight-medium;
  cursor: pointer;
  display: flex;
}

.rounded {
  border-radius: 18pt;
}

.underline {
  &:after {
    border-bottom: 1px solid $light-grey;
  }
}

.filter {
  color: var(--primary);
  font-weight: $font-weight-medium;
  cursor: pointer;
  margin-left: 1rem;
}
