@import "shared/src/sass/abstracts/mixins";

.statsWrapper {
  flex: 1;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 20px;

  &.clickableStyle {
    cursor: pointer;
  }

  .header {
    @include font-h6-style;
    color: var(--darkGrey);
  }

  .value {
    @include font-hero-style;

    &.linkStyle {
      color: var(--primary);
    }
  }
}