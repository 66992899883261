@import "shared/src/sass/abstracts/mixins";

.editIconContainer {
  flex-grow: 1;
  padding: 5px 0 0 5px;

  .editIcon {
    font-size: 17px;
    color: var(--primary);
    cursor: pointer;
  }
}

.groupId {
  @include font-h6-style;
  margin-top: -20px;

  @media screen and (max-width: $max-phone) {
    padding: 0 15px;
  }
}

.groupTerms {
  margin-top: 33px;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 4px;

  .tabContent {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;

    .description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include font-subtext-style;
      color: var(--darkGrey);
    }
  }

  .termsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .termColumnsWrapper {
      display: flex;
      justify-content: flex-start;
      @include font-h6-style;

      .termColumnHeader {
        color: var(--darkGrey);
      }

      .termColumnHeader:first-child, 
      .labelWrapper {
        display: flex;
        width: 220px;
      }

      .labelWrapper {
        display: flex;
        color: var(--black);
        align-items: center;
      }

      .termColumnHeader:last-child,
      .fieldWrapper {
        width: 300px;
      }
    }
  }  
}