@import "../../sass/abstracts/variables";
@import './ToastContainer.module.scss';

// Common styles for toast background
@mixin toastBackground($bgColor, $borderColor) {
  background-color: $bgColor;
  border: 1px solid $borderColor;
  border-radius: 4px;
  box-shadow: none;
  min-height: 51px;
  padding: 13px 16px;
  box-shadow: 0 4px 8px var(--darkGrey);
}

// Common styles for toast body
@mixin toastBody($color) {
  color: $color;
  padding: 0;
}

.toastWrapper {
  display: flex;
  justify-content: space-between;
}

.toastMessage {
  font-family: "Roboto";
  font-weight: $font-weight-regular;
  font-size: 16px;
  line-height: 24px;
}

.toastStatus {
  font-family: "Roboto";
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .1rem;
  padding-left: 16px;
  white-space: nowrap;
}

.error {
  &Background {
    @include toastBackground(var(--errorFill), var(--error));
  }

  &Body {
    @include toastBody(var(--error));
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
    padding: 0;
  }
}

.info {
  &Background {
    @include toastBackground(var(--activeFill), var(--primary));
  }

  &Body {
    @include toastBody(var(--primary));
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  }
}

.success {
  &Background {
    @include toastBackground(var(--successFill), var(--success));
  }

  &Body {
    @include toastBody(var(--success));
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  }
}

.loading {
  &Background {
    @include toastBackground(var(--white), var(--border));
  }

  &Body {
    @include toastBody(var(--primary));
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  }
}

.warning {
  &Background {
    @include toastBackground(var(--warningFill), var(--warning));
  }

  &Body {
    @include toastBody(var(--warning));
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  }
}