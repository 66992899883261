@import "shared/src/sass/abstracts/mixins";

.listContainer {
  margin: 15px 0;
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-radius: 5px;

  @media screen and (max-width: $max-phone) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .firstCell {
    padding-left: 10px !important;
    padding-right: 0 !important;
    display: flex;
    justify-content: center;
  }

  .lastCell {
    padding-right: 0 !important;
  }

  .paginationWrapper {
    padding: 0 20px;
  }

  .statusCell {
    span.final {
      color: var(--success);
    }

    span.draft {
      color: var(--warning);  
    }

    span.deleted {
      color: var(--error);
    }
  }
}