@import "shared/src/sass/abstracts/variables";

.mainContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 320px;
  // New design is 33px gap. 15px from .contentWrapper TODO: legacy cleanup. 
  padding: 0 18px 18px; 
  background-color: $snow;
  overflow-x: auto;
  height: 100%;

  @media screen and (max-width: $max-phone) {
    padding: 0 0 18px;
  }

  &.legacy {
    background-color: $white;
    padding: 0;
  }
}

.navbarWrapper {
  display: flex;
  flex-direction: row;
  height: calc(100% - 64px);
  overflow: hidden;

  @media (min-width: $breakpoint-extra-large) {
    margin-top: 64px;
  }
}
