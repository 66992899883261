@import "shared/src/sass/abstracts/mixins";

.listContainer {
  margin: 15px 0;
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-radius: 5px;

  @media screen and (max-width: $max-phone) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}