@import 'shared/src/sass/abstracts/mixins';

.modalContainer {
  background-color: var(--white);
  border-radius: 4px;
  position: absolute;
  width: 652px;
  height: auto;
  padding: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  border-top: 3px solid var(--primary);
  box-shadow: 0 16px 24px var(--black);

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .modalTitle {
      @include font-section-title-style;
    }

    .closeIcon {
      border: 0;
      background: none;
      color: $dark-grey;
      cursor: pointer;
      display: inline-block;
      font-size: 1.2rem;
      font-weight: 600;
      position: absolute;
      top: 24px;
      right: 23px;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}