.tab {
  display: flex;

  @media print {
    display: none;
  }
}

.tabList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 2px solid var(--grey100);
  background-color: var(--primary);
}

.whiteTabList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid var(--grey100);
}

.noUnderline {
  border-bottom: none;
}
