@import "shared/src/sass/abstracts/mixins";

$underline-width: 3px;

@mixin base-tab-style {
  @include font-h6-style;
  @include reset-button;

  padding: 20px;
  color: var(--darkGrey);
}

// do i need this? might be able to remove
@mixin focus-overlay {
  &::after {
    @include focus-indicator-disappear;
    border-radius: 4px;
    bottom: -$underline-width;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.tab {
  @include base-tab-style;

  background-color: var(--primary);
  color: var(--greyBlue);

  &:hover {
    border-bottom: $underline-width solid var(--greyBlue);
    color: var(--white);
    padding-bottom: 16px - $underline-width;
  }

  &KeyboardFocused {
    &:focus {
      color: var(--white);
      @include focus-indicator-appear-inset-external-focus;
    }
  }

  &Selected {
    border-bottom: $underline-width solid var(--white);
    color: var(--white);
    padding-bottom: 14px;
  }
}

.whiteTab {
  @include base-tab-style;
  cursor: pointer;

  &KeyboardFocused {
    &:focus {
      color: var(--primary);
      @include focus-indicator-appear-inset-external-focus;
    }
  }

  &Selected {
    border-bottom: $underline-width solid var(--primary);
    color: var(--primary);
  }
}
