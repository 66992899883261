@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

.suggestionPopper {
  z-index: 1200; // 1200 to get it over the Material UI Drawer
  position: relative;
  left: 0px;
  top: 100%;
  width: 100%;
  transform: none !important;
  background: var(--white);

  .suggestionOptions {
    position: absolute;
    width: 100%;
    box-shadow: 0px 1px 2px $medium-grey;
    border-radius: rem-calc(5);
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: none;
    padding-inline-start: 0;
    margin: 0;
  }

  .suggestionOption {
    width: 100%;
    display: flex;
    position: relative;
    background-color: var(--white);
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding: 12px 8px;
    justify-content: flex-start;
    text-decoration: none;
    &:hover {
      background: $active-fill;
    }
  }
}

.beforeContainer {
  padding-top: rem-calc(10);
  margin-right: rem-calc(5);
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.errorText {
  display: block;
  color: $error;
  font-size: rem-calc(16);
  margin: rem-calc(2) rem-calc(5) 0 1rem;
}

.input {
  border: none;
  border-radius: rem-calc(4);
  outline: none;
  flex-grow: 1;
  background-position: 100% 50% !important;
  background-color: inherit;
  resize: none;
  @include font-paragraph-style;

  &::placeholder {
    color: $dark-grey-blue;
  }
  &:focus {
    border: none;
    outline: none;
  }
  &:focus-visible {
    border: none;
    outline: none;
  }
  &:active {
    border: none;
    outline: none;
  }
}

.inputWrapper {
  border: 1px solid var(--border);
  border-radius: rem-calc(5);
  background-color: var(--white);
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  flex: 1;

  @include focus-indicator-appear;

  &.disabled {
    background-color: $light-grey;
  }
  &.error {
    border-color: $error;
    border: 1px solid $error;
    background-color: $error-fill;
  }
  &.rounded {
    border-radius: rem-calc(18);
  }
  &:hover {
    border: 1px solid $primary;

    html[data-theme="admin"] & {
      border: 1px solid $primary-admin;
    }
  }
  &:focus {
    border: 1px solid $yellow;
    box-shadow: 0 0 0 rem-calc(3) $yellow;
    outline: none;
  }
}

.startAdornment {
  margin-right: 8px;
}

.endAdornment {
  margin-left: 8px;
}
.inputMouseBlurred {
  transition: none;
}

.inputMouseFocused {
  transition: none;
  &:focus {
    border: 1px solid $primary;
    box-shadow: 0 0 0 1px $primary;

    html[data-theme="admin"] & {
      border: 1px solid $primary-admin;
      box-shadow: 0 0 0 1px $primary-admin;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.rowCentered {
  display: flex;
  flex-direction: row;
  align-items: center;
}
