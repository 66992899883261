@import "shared/src/sass/abstracts/mixins";

.formContainer {
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 20px;

  .endAdornment {
    color: var(--primary);
    cursor: pointer;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;

    button {
      flex-grow: 1;
    }
  }
}