.statisticsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .statsRow {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }
}