@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

.h1 {
  font-size: rem-calc(24);
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 1;
}

.medium {
  font-weight: 500;
}

.png {
  margin-top: rem-calc(16);
}

.link {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.columnDiv {
  display: flex;
  flex-direction: column;
}

.pointerDiv {
  cursor: pointer;
  height: 17px;
}

.darkIcon {
  color: var(--darkGrey);
}

.smallText {
  font-size: rem-calc(14);
}

.searchBarContainer {
  margin-bottom: rem-calc(26);
}

.title {
  font-weight: 400;
  font-size: rem-calc(36);
  letter-spacing: rem-calc(0.63);
  margin-bottom: rem-calc(24);
}

.smallTitle {
  display: block;
  font-size: rem-calc(12);
  letter-spacing: rem-calc(1.88);
  line-height: rem-calc(16);
  font-weight: 500;
  color: var(--darkGrey);
  margin-bottom: rem-calc(8);
}

.titleWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.blockSpan {
  display: block;
}

.inputWithNote {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  &.textfieldWrapper {
    margin-top: 18px;
  }
}

.input {
  width: rem-calc(398);
}

.noteContainer {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.note {
  height: max-content;
  display: flex;
  flex-direction: column;
  width: rem-calc(469);
  background-color: var(--lightGrey);
  border: 1px solid var(--border);
  border-radius: rem-calc(4);
  color: var(--darkGrey);
  font-size: rem-calc(14);
  letter-spacing: rem-calc(0.13);
  line-height: rem-calc(21);
  padding: rem-calc(8);
  margin-bottom: rem-calc(16);
}

.select {
  width: rem-calc(398);
}

.editButton {
  display: flex;
  height: rem-calc(16);
  width: rem-calc(72);
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
}

.modalHeader {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 28px;
}

.modalHeading {
  align-self: center;
  color: var(--black);
  display: block;
  font-size: rem-calc(24);
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}

.modalText {
  margin-bottom: 36px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 48px;
}

.toggleButton {
  @include focus-indicator-appear(1px);
  @include reset-button;

  align-items: center;
  border: 1px solid var(--lightGrey);
  border-radius: 6px;
  color: var(--darkGrey);
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: rem-calc(12);
  font-weight: 500;
  height: 154px;
  letter-spacing: rem-calc(1.88);
  line-height: 1.1666;
  justify-content: center;
  margin-right: 8px;
  text-align: center;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: var(--lightBlue);
    cursor: pointer;
  }

  &Icon {
    color: var(--darkGrey);
    margin-bottom: 17px;

    &Selected {
      color: var(--primary);
    }
  }

  &Selected {
    background-color: var(--lightBlue);
    border: 1px solid var(--primary);
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 20px;
}

.metric {
  display: flex;
  flex-direction: column;
  margin-bottom: rem-calc(16);
}

.metricColumn {
  display: flex;
  flex-direction: column;
  margin-right: rem-calc(60);
}

.dataItem {
  display: block;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(0.15);
  line-height: rem-calc(24);
}

.tableButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  letter-spacing: 1.44px;
  padding-left: 22px;
  padding-right: 22px;

  &Icon {
    margin-right: 6px;
  }
}

.downloadButton {
  margin: 0 0 rem-calc(24) !important;
}

// added button. to be more specific then rules being overridden...
button.importButton {
  display: initial;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
}

.dataSetTitle {
  color: var(--black);
}

.dataSetsTitleWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.dataSetDescription {
  font-size: rem-calc(14);
  line-height: 1.143;
  padding-top: 5px;
}

.status {
  &Published {
    color: var(--success) !important;
  }

  &Unpublished {
    color: var(--error) !important;
  }
}

.titleCell {
  width: 380px;
}


/** REDESIGN **/
.dataSetContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: $max-phone) {
    padding: 0 20px;
  }

  .dataSetInfo {
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    gap: 5px;

    .dataItemWrapper {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: baseline;

      .propTitle {
        @include font-h6-style;
        color: var(--darkGrey);
      }

      .dataValue {
        @include font-paragraph-style;
      }
    }
  }

  .previewContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .previewHeaderWrapper {
      display: flex;
      flex-direction: column;
    
      .previewHeader {
        @include font-h4-style;
        color: var(--darkGrey);
      }

      .description {
        @include font-paragraph-style;
        color: var(--black);
      }
    }

    .tableWrapper {
      border: 1px solid var(--border);
      border-radius: rem-calc(4);
      width: 100%;
      padding: rem-calc(16);
      background-color: var(--lightGrey);
      overflow-x: scroll;
      overflow-y: hidden;
    
      .headerRow {
        display: flex;
        font-weight: bold;
      }

      .dataRow {
        display: flex;
        border-radius: 4px;

        &.evenRow {
          background-color: transparent;
        }
        
        &.oddRow {
          background-color: #FBFDFF;
        }
      }

      .th,
      .td {
        flex: 1;
        padding: 8px;

        .propTitle {
          @include font-h6-style;
          font-weight: 700;
        }
        
        .propValue {
          @include font-paragraph-style;
        }
      }
    }
  }
}

.fieldLabel {
  @include font-h6-style;
  color: var(--darkGrey);
}

.fieldValue {
  @include font-paragraph-style;
}

.subtitle {
  margin-top: -20px;
  padding-bottom: 20px;
  @include font-paragraph-style;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}

.uploadContainer {
  

  > .subtitle {
    padding-bottom: 0;
  }

  .formSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid var(--border);
    padding: 20px 0;
    
    &:last-child {
      border-bottom: none;
    }
   
    .titleWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @include font-h4-style;

      .sectionHeader {
        display: flex;
        color: var(--primary);

        &Disabled {
          color: var(--grey100);   
        }
      }
    }
  }

  
}