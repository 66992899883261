@import 'shared/src/sass/abstracts/variables.scss';

.hazardIcon {
  cursor: pointer;
  fill: $hazard;
  width: 40px;
}

.hazardHide {
  display: none !important;
}

.hazardPopper {
  display: flex;
  top: 10px !important;
  height: 220px;
  z-index: 1000000;

  @media screen and (max-width: $max-tablet) {
    top: initial !important;
    bottom: 210px !important;
    left: -165px !important;
  }

  .popperWrapper {
    width: 520px;
    height: 200px;
    padding: 20px;

    @media screen and (max-width: $max-tablet) {
      width: 100%;
    }

    .photoUploadContainer {
      div {
        flex-direction: row;
        align-items: center;
      }

      @media screen and (max-width: $max-tablet) {
        div span:first-child {
          display: none;
        }

        button div span:first-child {
          display: initial;
        }
      }
    }
  }

  .hazardTitle {
    padding: 5px 0 0;
    color: $dark-grey;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .hazardDescription {

  }
}

.titleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $dark-grey;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 5px 0 20px;
 
  .cancel {
    color: $error;
    cursor: pointer;
  }
}

.hazardContent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
//   align-items: center;
  min-width: 300px;
  min-height: 300px;
  max-width: 674px;
  max-height: 350px;

  @media screen and (max-width: $max-tablet) {
    max-width: $breakpoint-extra-small;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
  }

  &.hazardContentLoading {
    max-height: 505px;
  }

  .hazardMessage {
    display: flex;
    overflow-y: scroll;
    padding-right: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $dark-grey;
    flex-grow: 1;

    @media screen and (max-width: $max-tablet) {
      overflow-y: visible;
    }

    &.messageLoading {
      display: none;
    }

    .hazardMessageText {
      ul {
        margin: 0;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
      }

      > ul {
        gap: 15px;
      }
    }
  }

  .hazardImageContainer {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    overflow: hidden;
    justify-content: center;

    img {
      object-fit: contain;
      max-width: 674px;
      max-height: 505px;
      border-radius: 10px;
      opacity: 0.25;

      @media screen and (max-width: $max-tablet) {
        max-width: $breakpoint-extra-small;
        max-height: 100%;
      }
    }

    &.imageFinish {      
      img {
        -webkit-animation: moveToFinishImg 2s ease;
        -moz-animation: moveToFinishImg 2s ease;
        -o-animation: moveToFinishImg 2s ease;
        animation: moveToFinishImg 2s ease;

        opacity: 1;
        max-width: 275px;
        max-height: 350px;
      }
    }
    
  }

  .loadingFFAnimation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .player {
      width: 300px;
      height: 300px;
    }

    &.hideLoading {
      display: none;
    }
  }
}

.desciption {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  padding-top: 15px;
  color: $dark-grey;

//   span:last-child {
//     color: $error;
//   }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;

  span:last-child {
    margin-left: 5px;
  }
}

@-webkit-keyframes moveToFinishImg {
  0% {
    opacity: 0.25;
    max-width: 674px;
    max-height: 505px;
  }

  50% {
    opacity: 0.25;
    max-width: 275px;
    max-height: 350px;
  }

  100% {
    opacity: 1;
    max-width: 275px;
    max-height: 350px;
  }
}

@-moz-keyframes moveToFinishImg {
  0% {
    opacity: 0.25;
    max-width: 674px;
    max-height: 505px;
  }

  50% {
    opacity: 0.25;
    max-width: 275px;
    max-height: 350px;
  }

  100% {
    opacity: 1;
    max-width: 275px;
    max-height: 350px;
  }
}

@-o-keyframes moveToFinishImg {
  0% {
    opacity: 0.25;
    max-width: 674px;
    max-height: 505px;
  }

  50% {
    opacity: 0.25;
    max-width: 275px;
    max-height: 350px;
  }

  100% {
    opacity: 1;
    max-width: 275px;
    max-height: 350px;
  }
}

@keyframes moveToFinishImg {
  0% {
    opacity: 0.25;
    max-width: 674px;
    max-height: 505px;
  }

  50% {
    opacity: 0.25;
    max-width: 275px;
  }

  100% {
    opacity: 1;
    max-width: 275px;
  }
}