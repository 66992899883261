@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

//const DateRange = styled(BaseDateRange)`
//margin-left: 15px;
//`;

.dateRange {
  margin-left: 15px;
}

.dateRangeRow {
  display: flex;
  padding-top: 8px;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  //className: "col-12", // todo
}

.pageTitle {
  color: $black;
  font-size: 2.25rem;
  letter-spacing: 0.63px;
}

.popperWrapper {
  // width: 320px;
  // height: 161px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -24px;
    top: 40%;
    // z-index: -1;
    width: 15px;
    height: 15px;
    background-color: $white;
    border-right: 1px solid rgba($black, 0.1);
    border-bottom: 1px solid rgba($black, 0.1);
    transform: rotate(-45deg) translate(-14px, -7px);
  }
}

.popperRow {
  align-items: center;
  color: $dark-grey;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  &Error {
    color: $error;
  }

  &Disabled {
    align-items: center;
    display: flex;
    cursor: initial;
    color: $dark-grey;
    opacity: 0.5;
    font-size: 0.875rem;
  }
}

.popperChevron {
  //className: "icon icon-icons8-chevron-left", // todo
  // todo override color
  color: $dark-grey !important;
  display: inline-block;
  transform: rotate(180deg);
  font-size: 25px;
  margin-left: 30px;
}

// Generic modal content
.modalPromptWrapper {
}

.modalPromptTitle {
  color: rgba($black, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
}

.modalPromptSubtitle {
  color: rgba($black, 0.87);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.28px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.modalPromptCloseIcon {
  //className: "icon-icons8-delete_sign", // todo
  border: 0;
  background: none;
  color: $dark-grey;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  position: absolute;
  top: 24px;
  right: 23px;
}

// Disable user modal content
.disableUserDataItem {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  max-height: 50vh;
  overflow-y: auto; 
  word-break: break-word;
}

.disableUserWarningLabel {
  background: $warning-fill;
  border-radius: 4px;
  border: $warning;
  color: rgba($dark-grey, 0.87);
  font-size: 0.875rem;
  padding: 16px;
  width: 100%;
}

.disableUserButtonsWrapper {
  bottom: 24px;
  display: flex;
  justify-content: flex-end;
  right: 23px;
  width: 100%;
  gap: 20px;
}

// Delete user modal content
.deleteUserWarningLabel {
  background: $error-fill;
  border: 1px solid $error;
  color: rgba($dark-grey, 0.87);
  font-size: 0.875rem;
  padding: 16px;
  width: 100%;
}
