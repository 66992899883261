@import "../../../sass/abstracts/mixins";

.wrapper {
  align-items: center;
  color: $primary;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  justify-content: center;
  letter-spacing: 0.21px;
  padding: 15px 0;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.link {
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  text-decoration: none;
}

.arrowUp {
  transform: rotate(180deg);
}
