@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";

@mixin commonInputWrapperStyles {
  padding: 16px 20px;
  border-bottom: 1px solid var(--mediumGrey);
}

@mixin commonTextStyles {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
}

.profileField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: $min-desktop) {
    display: block;
  }
}

.profileField_tablet {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--mediumGrey);
  padding: 16px 20px;
  margin-bottom: 19px;
}

.locked {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--mediumGrey);
  align-items: center;
  padding: 16px 20px;
  margin-bottom: 19px;
}

.lockedLabel {
  display: flex;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding-right: 1rem;
}

.right {
  flex: 1;
  flex-grow: 1.5;
  display: flex;
  justify-content: flex-end;

  @media (min-width: $min-desktop) {
    justify-content: flex-start;
  }
}

.valueWrapper {
  display: flex;
  justify-content: flex-end;
}

.label {
  color: var(--black);
  margin: 0;
  margin-bottom: 0.5rem;
}

.label_locked {
  margin-bottom: 0;
  color: var(--mediumGrey);
}

.value {
  @include commonTextStyles();
  color: var(--mediumGrey);

  margin: 0;
  align-self: flex-end;

  @media (min-width: $min-desktop) {
    margin: 0.5rem 1rem;
  }
}

.lockedWarning {
  display: flex;
  background-color: var(--warningFill);
  @media (max-width: $max-tablet) {
    padding: 16px 12px;
    margin: 26px;
  }

  @media (min-width: $min-desktop) {
    width: 100%;
    padding: 17px 24px;
    margin-bottom: 25px;
    margin-top: 1rem;
    align-items: center;
  }
}

.lockIcon {
  font-size: 1.5rem;
  margin-right: 12px;
}

.lockedWarningText {
  color: var(--darkGrey);
  letter-spacing: 0.51px;
  margin: 0;
}

.groupList {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 0;
  margin: 0;

  @media (max-width: $max-tablet) {
    align-items: flex-end;
  }
}

.groupLabel {
  color: var(--black);
  text-align: left;
  list-style-type: none;
}

.groupLabel_locked {
  color: var(--mediumGrey);
}

.renderGroups {
  padding: 5px 0;
  cursor: default;
  display: flex;
  justify-content: space-between;
  border-bottom: none;
}

.renderGroups_desktop {
  padding: 0;
}

.renderGroups_locked {
  cursor: pointer;
}

.primaryGroupSelect {
  padding-right: 30px !important;

  @media (max-width: $min-desktop) {
    padding-left: 0 !important;
  }
}

.form {
  width: 100%;
}

.label__text_locked {
  color: var(--mediumGrey) !important;
  margin: 0;
  padding: 6.5px 0px 0px 0px;
}

.labelRow {
  display: flex;
  align-items: center;
  margin-bottom: 11.5px;
}

.row {
  display: flex;
}
