@import "shared/src/sass/abstracts/mixins";

.responseBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--activeFill);


  .responseBar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: var(--activeFill);
    color: var(--secondary);
    padding: 10px;

    .percentOfResponses {
      display: flex;
      flex-direction: row;
      @include font-paragraph-bold-style;
      gap: 21px;

      h4 {
        @include font-h4-style;
        color: inherit;
        width: 50px;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .totalResponses {
    position: relative;

    div {
      position: absolute;
      white-space: nowrap;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      color: var(--secondary);
      @include font-h6-style;
    }
  }
}