@import "../../../../sass/abstracts/mixins";

.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -13px;
  border: 14px solid $primary;
  background-color: $secondary;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  min-width: 75px;
  min-height: 75px;
  z-index: 10;

  html[data-theme="admin"] & {
    border-color: $primary-admin;
    background-color: $secondary-admin;
  }

  .circularMenuBtn {
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    background-color: $white;
    color: $primary;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    html[data-theme="admin"] & {
      color: $primary-admin;
     }
  }

  .styledIcon {
    font-size: 1.4rem;
  }
}

.circularMenuContainer {
  position: absolute;
  top: 150px;
  z-index: -1;
  transition: top 200ms;

  &.active {
    top: 0px;
  }
}

.pie {
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  height: 300px;
  left: -150px;
  position: absolute;
  top: -150px;
  width: 300px;

  &:hover {
    opacity: 0.85;
  }
    
  &:active {
    opacity: 0.7;
  }

  &.hazard, &.newDoc, &.chat {
    clip-path: polygon(50% 50%, 101% 64%, 100% 0);
    transition-delay: 30ms;
  }

  &.hazard, &.chat {
    background: radial-gradient(circle at 150px 150px, $primary-gradient-dark, $primary-gradient-light);

    html[data-theme="admin"] & {
      background: radial-gradient(circle at 150px 150px, $primary-gradient-dark-admin, $primary-gradient-light-admin);
    }
  }
  
  &.newDoc {
    background: radial-gradient(circle at 150px 150px, $primary-gradient-alt-dark, $primary-gradient-alt-light);

    html[data-theme="admin"] & {
      background: radial-gradient(circle at 150px 150px, $primary-gradient-alt-light-admin, $primary-gradient-alt-dark-admin);
    }
  }

  &.hazard {
    transform: rotate(225deg);
  }

  &.newDoc {
    transform: rotate(285deg);
  }

  &.chat {
    transform: rotate(345deg);
  }
}

.iconText {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  left: 215px;
  position: absolute;
  top: 100px;
  font-size: 12px;
  flex-direction: column;
  color: $primary-fill;

  html[data-theme="admin"] & {
    color: $primary-fill-admin;
  }

  .hazard & {
    rotate: 135deg;
  }

  .newDoc & {
    rotate: 75deg;
  }

  .newDocIcon {
    fill: $primary-fill;
    width: 2rem;

    html[data-theme="admin"] & {
      fill: $primary-fill-admin;
    }
  }

  .chat & {
    rotate: 15deg;
  }

  .hazardIcon {
    fill: $primary-fill;
    width: 2rem;

    html[data-theme="admin"] & {
      fill: $primary-fill-admin;
    }
  }
}

.plus {
  cursor: pointer;
  font-size: 30px;
  transition: rotate 300ms;

  .active & {
    rotate: 45deg;
  }
}
