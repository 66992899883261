@import "shared/src/sass/abstracts/variables";

.apiDSButtonsWrapper {
  display: flex;
  gap: 20px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .fieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
  }
}

