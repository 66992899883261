@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";

.downloadResultsLoader {
  padding: 12px;
}

.icon {
  color: var(--primary);
}

.summaryWrapper {
    display: flex;
    align-items: flex-end;
}

.showAll {
  margin: 0;
  margin-right: 15px;
  cursor: pointer;
}

.wrapper {
  align-items: center;
  background: var(--activeFill);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  margin: 0 20px;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  color: var(--primary);
  height: 68px;

  @media print {
    padding: 0;
    min-height: auto;
  }
}

.exportClearContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;

  .summaryExports {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;

    @media print {
      display: none;
    }

    .actionButton {
      margin: 0;
    }

    .downloadButton {
      > div:first-child {
        margin: 0;
      }  
    }
  }

  .clearFilters {
    padding: 7px 12px;
    border: 1px solid var(--error);
    border-radius: 4px;
    background-color: var(--white);
    color: var(--error);
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-style: normal;
    cursor: pointer;

    &:hover {
      background-color: var(--error);
      color: var(--white);
    }
  }
}