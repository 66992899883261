@import "shared/src/sass/abstracts/mixins";
@import "shared/src/components/Filters/Filters.module";

.editIconContainer {
  flex-grow: 1;
  padding: 5px 0 0 5px;

 .editIcon {
   font-size: 17px;
   color: var(--primary);
   cursor: pointer;
  }
}

.profileWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -10px;
  gap: 20px;

  @media screen and (max-width: $max-phone) {
    padding: 0 20px;    
  }

  .profileInformationWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .profileContentWrapper {
      display: flex;
      flex-direction: column;

      .profileContentHeader {
        @include font-h6-style;
        color: var(--darkGrey);
      }

      .profileContent {
        @include font-paragraph-style;
        color: var(--black);

        a {
          color: var(--primary);
        }
      }
    }
  }
}

.statsWrapper {
  padding-top: 33px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: $max-phone) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.allUserDocs {
  padding: 33px 0 18px 0;
  @include font-h6-style;
  color: var(--darkGrey);  
}

.listContainer {
  display: flex;
  flex-direction: column;
  margin: 15px 0 0;
  padding: 20px 0 0;
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-radius: 5px;
  gap: 20px;

  @media screen and (max-width: $max-phone) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .filtersContainer {
    padding: 0 20px;
  }

  .documentsList {
    padding-top: 20px;
  }

  .firstCell {
    padding-left: 20px !important;
  }

  .lastCell {
    padding-right: 0px !important;
  }

  .paginationWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }
}