@import "shared/src/sass/abstracts/mixins";

.responseSummaries {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px;
  border-top: 1px solid var(--grey100);

  &:first-child {
    border-top: none;
  }

  .responseSummaryHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h4 {
        @include font-h4-style;
        color: var(--darkGrey);
      }

      .exportAll {
        @media print {
          display: none;
        }
      }

      .detailsTitle {
        @include font-paragraph-bold-style
      }

      .totalResponses {
        @include font-paragraph-style;
        color: var(--errieBlack)
      }
    }
  }

  .responses {
    .mostPopular {
      display: flex;
      flex-direction: row;
      gap: 50px;

      .column {
        display: flex;
        flex-direction: column;

        span {
          @include font-paragraph-style;
          position: relative;
          padding-left: 20px;
          color: var(--errieBlack);
        
          &::before {
            content: '•';
            position: absolute;
            left: 5px;
          }
        }
      }
    }

    .responsesChart {
      display: flex;
      flex-direction: column;
      gap: 20px;      
    }
  }

  &.safetyRatings {
    .mostPopular {
      @include font-paragraph-bold-style;
    }

    .viewExportAllComments {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    .questionsWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include font-paragraph-style;
    }
  }
}