.fieldMessagingWrapper {
  display: flex;
  flex-direction: column;

  .bottomRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .errorWarningContainer {
      display: flex;
      flex-direction: row;
    }
  }

  /* this should hide the bottom row messages if nested in another wrapper */
  .fieldMessagingWrapper {
    .bottomRow {
      display: none;
    }
  }
}