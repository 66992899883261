@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.outlined {
  border-color: $grey-blue;
}

.container {
  align-items: center;
  display: flex;
}
.rangeSeparator {
  color: $black;
  padding: rem-calc(16);
}
.clearButton {
  color: $error;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
}

.label {
  color: $black;
  font-size: rem-calc(20);
  font-weight: 500;
  margin-top: rem-calc(16);
  margin-bottom: 0;

  &.noMargin {
    margin-top: 0;
  }
  &.smallLabel {
    color: $dark-grey;
    margin-bottom: rem-calc(5);
    font-size: rem-calc(13);
  }
}

.column {
  display: flex;
  flex-direction: column;
}

