@import "shared/src/sass/abstracts/mixins";

.mainContainer {
  width: 100%;

  @media screen and (max-width: $max-phone) {
    padding: 0 15px;
  }

  .pageTitle {
    display: flex;
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
    justify-content: space-between;

    .titleText {
      padding-top: 10px;
    }

    > span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
