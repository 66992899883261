@import "shared/src/sass/abstracts/mixins";

.filtersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  padding: 0 15px;
  gap: 10px;

  .searchWrapper {
    display: flex;
    width: 100%;

    .filterButton {
      display: none;
    }

    @media screen and (max-width: $max-phone) {
      .filterButton {
        display: inline-flex;
        margin-left: 14px;
        width: 12px;
        padding: 12px 24px;
      }
    }
  }

  .filterOptions {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;

    @media screen and (max-width: $max-phone) {
      .tabletDesktop {
        display: none;
      }
    }
  }
}
