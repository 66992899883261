@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.formWrapper {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.errorText {
  background: $warning-fill;
  border-radius: 4px;
  border: $warning;
  color: rgba($dark-grey, 0.87);
  font-size: 0.875rem;
  padding: 16px;
  width: 100%;
}

.iconSelectionArea {
  background: #f0f0f0;
  border: 1px solid var(--icon-color);
  border-radius: 0px 0px 8px 8px;
  padding: 8px;
}