.contentBody {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 4px;

  .firstCell {
    padding-left: 20px !important;
  }

  .lastCell {
    padding-right: 0 !important;
  }

  .paginationWrapper {
    padding: 0 20px;
  }
}