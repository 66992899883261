@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.calendarIcon {
  fill: var(--darkGreyBlue);
  height: rem-calc(24);
  margin-right: rem-calc(8);
  width: rem-calc(24);

  &.calendarIconActive {
    fill: var(--primary);
  }
}
.dateButton {
  @include font-body-regular;
  @include focus-indicator-disappear;
  background-color: var(--white);
  border-color: var(--grey100);
  border-radius: rem-calc(4);
  border-style: solid;
  border-width: 1px;
  color: var(--darkGrey);
  display: inline-flex;
  margin: 0;
  padding: rem-calc(10);
  font-size: 14px;

  &.disabled {
    background-color: var(--lightGrey);
  }

  &.dateButtonActive {
    @mixin active-border-overlap-appear {
      box-shadow: 0 0 0 1px var(--primary);
      border-color: var(--primary);
    }
  }

  &.dateButtonKeyboardFocused {
    &:focus {
      @mixin focus-indicator-overlap-appear {
        border-color: $focus-indicator-outline;
        box-shadow: 0 0 0 rem-calc(3) $focus-indicator-outline;
      }
    }
    outline: none;
  }
  
  &.dateButtonMouseFocused {
    transition: none;
  }
}
