.formContainer {
  padding: 20px;

  .formFieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .companyAddress {
      display: flex;
      flex-direction: column;
      gap: 20px;  
    }
  }
}