@import "shared/src/sass/abstracts/mixins";

.version {
  @include font-h6-style;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 20px;
}

.listContainer {
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-radius: 5px;

  @media screen and (max-width: $max-phone) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .helpTitle {
    @include font-h6-style;
    color: var(--darkGrey);
    padding: 20px;
  }

  .filtersContainer {
    padding: 0 20px;
  }

  .questionsContainer {
    @include font-paragraph-bold-style;
    color: var(--primary);
    
    .mainQuestion {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--border);
      padding: 20px;

      .questionContent { 
        display: flex;
        flex-direction: row;
        justify-self: space-between;
        cursor: pointer;
        gap: 20px;

        span:first-child {
          flex-grow: 1;  
        }

        .chevron {
          font-size: 20px;
        }
      }
    }
  }

  .paginationWrapper {
    padding: 0 20px;
  }
}

.feedbackContainer {
  margin-top: 33px;
  display: flex;
  flex-direction: column;
  gap: 33px;

  @media screen and (max-width: $max-phone) {
    padding: 0 20px;
  }

  .haveQuestion {
    display: flex;
    flex-direction: column;
    gap: 2px;

    span:first-child {
      @include font-h4-style;
    }

    span:last-child {
      @include font-paragraph-style;
      color: var(--black);
    }
  }
}
 