.modalContent {
  color: var(--black);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.28px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}