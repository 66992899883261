@import "shared/src/sass/abstracts/mixins";

.arrow {
  color: var(--darkGrey);
}

.lightTooltip {
  display: flex;
  background-color: $white !important;
  border: 1px solid $light-grey;
  box-shadow: 0px 1px 3px var(--black);
  color: var(--black) !important;
  position: relative;
  max-width: none;
  white-space: nowrap;
  max-width: 375px !important;
}

.tooltip {
  background-color: var(--darkGrey) !important;
  border-color: var(--darkGrey);
  color: $white !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5;
}
